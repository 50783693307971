import React, { useEffect } from 'react';
import { useRegion } from '../hooks/useRegion';
import Layout from '../components/layout';
import Header from '../components/header';
import SectionOpener from '../sections/section-opener/section-opener';
import { resolve } from '../urls';
import Link from '../components/link';

const DeimPage = (props) => {
  const { whatRegion } = useRegion();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href =
        'https://drive.google.com/file/d/1PtWIFSioAJZbdgBAfjjbg3Kuf6bV_hL9/view';
    }, 0);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="page">
      <Layout>
        <SectionOpener openerType="404">
          <Header />
          <p>
            If the page does not automatically redirect, please{' '}
            <a href="https://drive.google.com/file/d/1PtWIFSioAJZbdgBAfjjbg3Kuf6bV_hL9/view">
              click here
            </a>
          </p>
          <div className="error-link">
            <Link className="button-icon" to={resolve('index', { locale: whatRegion })}>
              Click here to return Home.
            </Link>
          </div>
        </SectionOpener>
      </Layout>
    </div>
  );
};

export default DeimPage;
